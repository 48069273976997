@use '../assets/base.scss';


.events-wrapper{
	padding-top: 100px; 
    margin: 0 50px 0 50px; 
    font-family: base.$font-primary;
    a{
        color: black; 
    }

	.event{
		text-align: center; 
		img{
			height: 80vh; 
			margin: 0 auto;  
		}
		@media (max-aspect-ratio: 1/1){
			img{
				height: 100%; 
				width: 100%; 
			}
		}
	}
}