@use "../assets/base.scss"; 
.newspage-wrapper{
    background-color: base.$background-color1;
    font-family: base.$font-primary; 
    margin: 0 50px 0 50px; 
    padding-top: 100px; 
    height: calc(100vh - 100px); 
    display: grid;
    grid-template-columns: 25vw 1fr; 
    grid-column-gap: 50px; 
    .newspage-header{
        font-family: base.$font-secondary;
        font-style: italic; 
        padding-top: 75px; 
        font-size: 40px; 
        font-weight: 500;
    }
    .newspage-news-wrapper{
        .newspage-news-article{
            margin: 0px 0 50px 0; 
            padding: 3px 0 7px 0; 
            cursor: pointer; 
            display: grid; 
            grid-template-columns: 1fr max-content;
            border-bottom: 2px solid black; 
            h3{
                margin: 0 40px 0 0; 
                font-size: 25px; 
                font-weight: 600; 
                 
                overflow: hidden; 
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            p{
                margin: 0; 
                font-weight: 300; 
            }
        }
    }
    @media screen and (max-width: base.$mobile-breakpoint1){
        margin: 0 20px 0 20px; 
        grid-template-columns: 1fr; 
        grid-template-rows: min-content min-content; 
        .newspage-header{
            margin-bottom: 40px; 
        }
    }
}
#newspage-wrapper-portal{
    grid-template-columns: 1fr; 
    grid-template-rows: min-content min-content;
    .newspage-header{
        padding-top: 0px; 
        margin-bottom: 20px; 
    }
}