@use '../clearweb/assets/base.scss'; 

.tooltip-component-wrapper{
	position: relative; 
	button{
		font-size: 15px; 
		text-decoration: underline;
		background-color: transparent;
		border: none; 
		font-family: base.$font-primary; 

	}
	button:hover{
		cursor: pointer
	}

	.tooltip-body{
		background-color: base.$primary-color;
		position: absolute;
		font-weight: 400; 
		z-index: 2; 
		display: grid; 
		grid-template-columns: 1fr auto; 
		width: 250px; 
		opacity: 0; 
		visibility: hidden;
		padding: 10px; 
		transition: 0.3s linear; 
		border-radius: 7px; 
		transform: translate(0, -160%); 
		a:hover{
			cursor: pointer; 
		}
		box-shadow: 0 0 7px 8px rgba(230,230,230,0.3); 

	}
	.tooltip-body-show{
		visibility: visible;	
		opacity: 1; 
		transition: 0.3s linear; 
	}
}