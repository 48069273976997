@use "../assets/base.scss";
.biopopup-wrapper{
	
	font-size: 15px; 
	font-family: base.$font-primary;
	img{
		border-radius: 50%; 
	}

	.biopopup-card-wrapper{
		position: relative; 
		.biopopup-card{
			text-align: center; 
			background-color: base.$primary-color; 
			padding: 20px; 
			border-radius: 10px; 
			img{
				border-radius: 50%; 
				width: 90%; 
				aspect-ratio: 1 / 1; 
			}
			h1{
				padding-top: 10px; 
				font-size: 17px; 
				font-weight: 400; 
			}
			h2{
				margin: 0; 
				font-size: 15px; 
				font-weight: 300; 
			}
			transition: 0.3s linear; 
			transition-property: opacity; 
		}
		.biopopup-card-bio:hover{
			text-decoration: underline;
			cursor: pointer; 
		}

		.biopopup-card-hide{
			img,h1,h2{
				opacity: 0; 
				transition: 0.3s linear; 
				transition-property: opacity; 
			}
			

		}
		.biopopup-slideup{
			position: absolute;
			opacity: 0; 
			top: 0; 
			height: 90%; 
			width: 90%; 
			background-color: base.$primary-color; 
			padding: 10px; 
			border-radius: 10px; 
			transition: 0.3s linear; 
			transition-property: opacity; 

		}
		.biopopup-slideup:hover{
			cursor: pointer; 
		}
		.biopopup-slideup-show{
			opacity: 1; 
			transition: 0.3s linear; 
		}
	}

	.biopopup-popup{
		overflow-y: scroll;  
		-ms-overflow-style: none; 
  		scrollbar-width: none;
		position: fixed; 
	    top: 50%; left: 50%; 
	    width: 80vw; height: 80vh; 
	    transform: translate(-50%, 20px); 
		text-align: center; 
		top: 65px; 
		background-color: base.$primary-color; 
		padding: 20px; 
		border-radius: 10px; 
		z-index: 2; 
		visibility: hidden; 
		opacity: 0; 
		img{
			padding: 20px; 
			aspect-ratio: 1/1; 
			width: min(80%, 200px); 
			margin: 0 auto 0 auto; 
		}
		h1{
			padding-top: -5px; 
			font-style: italic; 
			font-size: 15px; 
			font-weight: 400; 
			color: rgb(40,40,40); 
		}
		p{
			text-align: left; 
		}
	}
	.biopopup-popup::-webkit-scrollbar{
		display:none; 
	}
	.biopopup-popup-show{
		visibility: visible;
		opacity: 1;
		transform: translate(-50%, 0px);  
		transition: 0.3s linear; 
		transition-property: transform, opacity; 
	}
	.close{
		font-size: 17px; 
		width: 20px; 
		float: right; 
		position: sticky ; 
		top: 0; 
	}
	.close:hover{
		cursor: pointer; 
	}
}