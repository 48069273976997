.alert-wrapper{
	display: grid; 
	grid-template-columns: 1fr min-content; 
	position: fixed;
	width: 300px; 
	bottom: 20px; 
	left: 50%; 
	transform: translate(-50%, 0); 
	border-radius: 10px; 
	background-color: rgba(200, 200, 200, 0.9);
	.alert-body{
		margin-left: 10px; 
		font-size: 14px; 
	}
	button{
		margin-right: 10px; 
		background-color: transparent;
		border: none; 
		cursor: pointer;
	}
	.alert-close{
		background-color: transparent;
	}
	.alert-close:hover{
		background-color: transparent;
	}
}


.alert-wrapper-open{
	transition: 0.2s linear; 
	opacity: 1; 
	transition-properties: transform, opacity; 
}

.alert-wrapper-closed{
	transition: 0.2s linear; 
	transition-properties: transform, opacity; 
	opacity: 0; 
}