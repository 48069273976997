@use '../../clearweb/assets/base.scss';
.portal-login-wrapper{
    font-family: base.$font-primary;
    position: absolute; 
    width: 300px; height: auto; 
    top: 50%; left: 50%; 
    transform: translate(-50%, -50%); 
    display: grid; 
    grid-template-columns: 1fr; 
    grid-column-gap: 20px; 
    grid-row-gap: 5px; 
    background-color: base.$primary-color;
    padding: 20px; 
    border-radius: 10px; 
    .portal-login-header{
        grid-row: 1; 
        text-align: center;
        font-size: 30px;
        padding: 20px 0 20px 0;
    }
    button{
        font-size: 15px; 
        font-family: base.$font-primary;
        margin: 20px auto 0 auto; 
        background-color: transparent; 
        border:none;
        text-decoration: underline;
    }
    button:hover{
        cursor: pointer; 
    }
    input{
        height: 20px; 
        border: 0px solid transparent;
        background-color: rgb(247, 242, 255);
        padding: 5px; 
    }
    input:focus{
        outline: none; 
    }
}