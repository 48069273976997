@use '../clearweb/assets/base.scss';

.navbar.transparent {
    transition: 0.75s ease-in-out;
    background-color: rgba(255, 255, 255, 0.3);
    color: black;
}



.navbar {
    position: fixed;
    width: 100%;
    font-family: base.$font-primary;
    transition-property: background-color, color;
    transition-duration: 0.75s;
    transition-timing-function: ease-in-out;
    background-color: base.$background-color1;
    z-index: 1000;
    display: flex;
    top: 0;
    align-items: center;
    justify-content: space-between;
    height: 9vh;

    .active {
        background-color: #f0d3e5;
    }

    .nav__menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .nav__toggler {
        display: none;
        padding-right: 30px;
    }

    .nav__toggler div {
        width: 1.5rem;
        height: 0.1rem;
        margin: 0.25rem;
        background: black;
        transition: 0.3s ease-in;
    }

    .nav-item {
        list-style: none;
    }

    .nav-item a {
        display: block;
        padding: 20px 20px;
        white-space: nowrap;
        border-radius: 0.5rem;
    }

    @media screen and (max-width: 768px) {
        .nav__toggler {
            display: block;
            cursor: pointer;
        }

        .nav__menu {
            position: fixed;
            top: 7vh;
            right: 0;
            height: 93vh;
            width: 40vw;
            background: rgba(255, 255, 255, 0.8);
            flex-direction: column;
            transform: translateX(100%);
            transition: 0.5s ease-in;
            display: block;
            justify-content: space-between;
        }

        .nav-item a {
            display: block;
            padding-left: 50px;
            margin-top: 50px;
            white-space: nowrap;
            border-radius: 0.5rem;
            margin-right: 50px;
        }

    }

    @media screen and (max-width: base.$mobile-breakpoint1) {
        .nav__toggler {
            display: block;
            cursor: pointer;
        }

        .nav__menu {
            position: fixed;
            top: 7vh;
            right: 0;
            height: 93vh;
            width: 40vw;
            background: rgba(255, 255, 255, 0.8);
            flex-direction: column;
            transform: translateX(100%);
            transition: 0.5s ease-in;
            display: block;
            justify-content: space-between;
        }

        .nav-item a {
            display: block;
            padding-left: 20px;
            margin-top: 50px;
            white-space: nowrap;
            border-radius: 0.5rem;
            margin-right: 50px;
        }
    }

    .nav-link {
        color: black;
        font-size: 1 rem;
        font-weight: 600;
        margin: 0px 10px;
        text-decoration: none;
        transition: 0.3s ease-in-out;
    }

    .nav-link:hover {
        color: #e4b9d6;
    }

    .nav-link.active {
        color: #ffffff;
    }



    .nav__active {
        transform: translateX(0%);
    }
}

.logo-wrapper {

    padding-left: 50px;

    a {
        text-decoration: none;
    }

    h1 {
        color: black;
        margin: 15px 10px 10px 10px;
    }
}

.menu-container {
    padding-right: 60px;
}

.menu-icon {
    font-size: 25px;
    margin: 20px 10px;
    color: black;
}

.menu-content {
    color: black;
}


.toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 5px);
}

.toggle .line2 {
    opacity: 0;
}

.toggle .line3 {
    transform: rotate(45deg) translate(-5px, -5px);
}