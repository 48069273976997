@use "../clearweb/assets/base.scss"; 

@keyframes animateInstantiate{
    from{
        opacity: 0; 
        transform: translate(-50%, calc(-50% + 20px)); 
    }
    to{
        opacity: 1; 
        transform: translate(-50%, -50%); 
    }
}
@keyframes animateInstantiateMobile{
    from{
        opacity: 0; 
        transform: translate(-52.5%, calc(-50% + 20px)); 
    }
    to{
        opacity: 1; 
        transform: translate(-52.5%, -50%); 
    }
}
.newsarticle-wrapper{
    color: black; 
    z-index: 1001; 
    

    animation-name: animateInstantiate; 
    animation-duration: 0.3s;
    background-color: base.$primary-color;
    position: fixed; 
    top: 50%; left: 50%; 
    width: 80vw; height: 80vh; 
    transform: translate(-50%, -50%); 
    border-radius: 10px;
    display: grid; 
    grid-template-rows: min-content min-content 1fr; 
    padding: 0 50px 0 50px; 
    .newsarticle-xbtn{
        cursor: pointer;
        position: absolute; 
        top: 15px; right: 15px; 
        width: 25px; height: 25px; 
        #newsarticle-xbtn-line{
            margin: 0;  
            width: 20px; 
            border: 1px solid black; 
        }
        .newsarticle-xbtn-line1{
            transform: translate(0, 12px) rotate(45deg) ; 
        }
        .newsarticle-xbtn-line2{
            transform: translate(0, 10px) rotate(-45deg); 
        }
    }

    .newsarticle-subject{
        margin: 50px 80px 10px 0; 
        font-size: 35px; 
        font-weight: 600; 
    }
    .newsarticle-date{
        font-weight: 300;
        margin: 0 0 50px 0; 
    }
    .newsarticle-body{
        padding-right: 10px; 
        overflow-y: scroll; 
        margin-bottom: 20px; 

        img{
            display: block; 
            margin: 0 auto 0 auto; 
            width: 30%; 
        }
        
    }

    .contactmessage-header-wrapper{
        font-size: 20px; 
    }

}

@media screen and (max-width: base.$mobile-breakpoint1){
    .newsarticle-wrapper{
        animation-name: animateInstantiateMobile; 
        width: 85vw; 
        margin-left: 10px; margin-right: 10px;
        transform: translate(-52.5%, -50%); 
        padding-left: 20px; padding-right: 20px; 
        .newsarticle-subject{
            font-size: 22px; 
            margin-right: 0px; 
        }
        .newsarticle-body{
            img{
                width: 70%; 
            }
        }
    }
}
