@use '../assets/base.scss'; 



.contact-wrapper{
	font-family: base.$font-primary; 
	padding-top: 100px; 
	margin-left: 50px; margin-right: 50px; 
	display: grid; 
	grid-template-columns: 33% 1fr; 
	.contact-header{
		grid-column: 1; 
        font-size: 40px; 
        font-style: italic;
        font-family: base.$font-secondary; 
		p{
			font-size: 20px; 
		}
		a{
			color: black; 
			text-decoration: underline; 
		}
	}
	.contact-form-wrapper{
		display:grid; 
		grid-column-start: 2;
		grid-template-columns: 1fr 1fr; 
		grid-template-rows: repeat(5, fit-content); 
		grid-column-gap: 80px; 
		grid-row-gap: 10px; 
		.contact-lbl{
			white-space: nowrap;
			font-style: base.$font-primary; 
			letter-spacing: base.$paragraph1-letter-spacing; 
			margin-right: 10px; 
		}
		.form-input{
			font-family: base.$font-primary; 
			width: 100%; 
			font-size: 15px; 
			margin-right: 10px; 
			margin-bottom: 20px; 
			padding-left: 5px; padding-right: 5px; 
			border: transparent;
			background-color: #EFEFEF; 
			border-bottom: 1px solid grey;
			border-radius: 0; 
			height: 25px; 
			transition: 0.3s ease-in-out; 
			transition-property: background-color border-bottom; 
		}
		.form-input:focus{
			transition: 0.3s ease-in-out; 
			transition-property: background-color border-bottom; 
			border-bottom: 1px solid black; 
			background-color: #E3E3E3; 
			outline: none; 
		}

		.input-wrap{
			display: grid;
			grid-template-columns: 120px 1fr; 
		}
		.input-fname{
			grid-row: 1; grid-column: 1; 
		}
		.input-lname{
			grid-row: 1; grid-column: 2; 
		}
		.input-email{
			grid-row: 2; grid-column-start:1; grid-column-end: 3; 
		}
		.input-subject{
			grid-row: 3; grid-column-start:1; grid-column-end: 3; 
		}
		.input-msg{
			grid-row: 4; grid-column-start:1; grid-column-end: 3; 
			.input-area{
				font-family: base.$font-primary; 
				resize: none; 
				height: 100px; 
			}
		}
		.contact-submit-btn{
			font-family: base.$font-primary; 
			font-size: 15px; 
			grid-row: 5; grid-column-start: 1; grid-column-end: 3; 
			width: 120px; height: 120px; 
			text-align: center; 
			margin-left: auto; margin-right: auto; 
			background-color: transparent;
			border-radius: 50%; 
		}
		.btn-nosuccess{
			border: 1px solid black;
			color: black;
			transition: 0.3s ease-in-out ; 
			transition-property: color border; 
		}
		.btn-success{
			border: 1px solid #4BB543;
			color: #4BB543;
			transition: 0.3s ease-in-out ; 
			transition-property: color border; 
		}

		.contact-submit-btn:hover{
			transition: 0.3s ease-in-out; 
			transition-property: color border; 
			cursor: pointer; 
			border: 1px solid base.$secondary-color; 
			color: base.$secondary-color; 
		}
	}
}

@media screen and (max-width: 900px){
	.contact-wrapper{
		margin-left: 20px; margin-right: 20px; 	
		display: grid; 
		grid-template-columns: 1fr; 
		grid-template-rows: min-content min-content; 
		.contact-header{
			margin: 0 auto 40px auto; 
			grid-column: 1; 
		}	
		.contact-form-wrapper{
			grid-column: 1; 
			font-size: 14px; 
			grid-template-rows: repeat(6, fit-content); 
			.input-wrap{
				.form-input{
					margin-right: 0; 
					padding-right: 0; 
					padding-left: 0; 
				}
			}
			.input-fname{
				grid-row: 1; grid-column-start:1; grid-column-end: 3;
			}
			.input-lname{
				grid-row: 2; grid-column-start:1; grid-column-end: 3;
			}
			.input-email{
				grid-row: 3; grid-column-start:1; grid-column-end: 3; 
			}
			.input-subject{
				grid-row: 4; grid-column-start:1; grid-column-end: 3; 
			}
			.input-msg{
				grid-row: 5; grid-column-start:1; grid-column-end: 3; 
				.input-area{
					font-family: base.$font-primary; 
					resize: none; 
					height: 100px; 
				}
			}
			.contact-submit-btn{
				grid-row: 6; grid-column-start: 1; grid-column-end: 3; 
			}
		}
	}
}