
@use '../../clearweb/assets/base.scss';
.register-page-wrapper{
    margin-top: 100px; 
    
    h1{
        text-align: center; 
    }
    .register-form{
        margin-left: 20px; margin-right: 20px; 
        display: grid; 
        grid-template-columns: 1fr 1fr; 
        grid-row-gap: 10px; 
        grid-column-gap: 10px;  
        
    
        p{
            margin: 0; 
        }
    
        input{
            height: 20px; 
        }
        select{
            height: 20px; 
        }
    
        
    }
    .submitbutton{
        margin-top: 40px; 
        text-align: center;
        button{
            border: none; 
            background: none; 
            text-decoration: underline;
            font-size: 15px; 
        }
        button:hover{
            cursor: pointer; 
        }

    }
}

