.associate-component-wrapper{
    h1{
        text-align: center;
    }
    .portal-associates-associate-profile-wrapper{
        h3{
            border-bottom: 1px solid black; 
            margin: 0 0 5px; 
            padding: 0 0 5px; 
        }
        margin: 0 0 30px 0; 
    }
}