@use '../assets/base.scss';

.friendspage-wrapper{
    padding-top: 100px; 
    margin: 0 50px 0 50px; 
    font-family: base.$font-primary;
    a{
        color: black; 
    }
    .friendspage-header{
        font-size: 40px; 
        font-style: italic;
        font-family: base.$font-secondary; 
    }
    .friendspage-headshots-wrapper{
        margin: 50px 0px; 
        display: grid; 
        justify-items:center; 
        align-items:center; 
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); 
        grid-gap: 10px; 
    }

    @media screen and (max-width: base.$mobile-breakpoint1){
    }
    .friendspage-description{
        margin: 0 0 50px 0; 
        font-size: 17px; 
    }
}