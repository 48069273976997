@use "../../clearweb/assets/base.scss"; 

.portal-dashboard-wrapper{
    font-family: base.$font-primary;
    display: grid; 
    grid-template-columns: 200px 1fr ;
    grid-template-rows: min-content 1fr; 
    margin-left: 20px; margin-right: 20px; 
    .portal-hello-header{
        height: min-content; 
        width: min-content; 
        margin-left: auto; margin-right: auto; 
        text-align: center; 
        margin-bottom: 20px; 
        .portal-user-logo{
            margin-left: auto; margin-right: auto; 
            border: 1px solid black ; 
            border-radius: 4px;
            padding: 10px; 
            width: 20px; 
            text-align: center;
            font-weight: 600; 
        }
        .portal-user-name{
            font-weight: 300; 
        }
    }

    .portal-tab-selector-wrapper{
        grid-row-start: 1; grid-row-end: 3; 
        margin-top: 40px; 
        font-weight: 600;
        overflow: hidden; 
        div{
            overflow: hidden;
        }
        p{
            cursor: pointer; 
            margin: 0; 
        }

        .portal-tab-underline-open{
            margin-bottom: 5px; 
            width: 40px; 
            border: 1px solid black; 
            transform: translate(0,0);
            transition: 0.3s linear transform; 
        }
        .portal-tab-underline-closed{
            margin-bottom: 5px;
            width: 40px; 
            border: 1px solid black; 
            transform: translate(-55px,0);
            transition: 0.3s linear transform; 
        }

    }

    .protal-selected-tab-wrapper{
        grid-column: 2; 
    
    }
}

@media only screen and (max-width: 600px){
    .portal-dashboard-wrapper{
        grid-template-columns: 1fr 40px;
        grid-template-rows: min-content min-content 1fr; 
        width: 100%; 
        margin: 0 20px 0 20px; 
        overflow: auto; 
        .portal-hello-header{
            grid-row-start: 0; grid-row-end:1; 
        }
        .portal-tab-selector-wrapper{
            grid-row: 1; 
            display: inline-block;
            div{
                margin: 0 10px 0 10px; 
                display: inline-block; 
            }

            .portal-tab-underline-open{
                transform: translate(-10px,0);
            }

        }
        .protal-selected-tab-wrapper{
            grid-column: 1; 
            grid-row: 3; 
        
        }
    }
}

