$font-primary: 'Lato', sans-serif;
$font-secondary: "Fredoka", sans-serif;
$primary-color: #d1d1e0;
$secondary-color: #ffcc00;
$background-color1: rgb(255, 255, 250);
$background-color2: rgb(32, 37, 51);
$mobile-breakpoint1: 800px;
$mobile-breakpoint2: 600px;


$paragraph1-letter-spacing: 0.3px;
$paragraph1-line-height: 23px;