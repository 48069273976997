.view-profiles-wrapper{
    h1{
    font-size: 20px;
        border-bottom: 1px solid black; 
        padding-bottom: 5px; 
        margin: 0 0 20px 0; 
    }
    .view-profiles-component-wrapper{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) ; 
        grid-gap: 10px; 
        grid-row-gap: 20px; 

        h1{
            text-align: center;
        }
        .view-profiles-profile{
            display: grid;
            grid-template-columns: min-content 300px; 
            h3{
                margin: 5px 0; 
            }
            h2{
                margin: auto 15px auto 0; 
                width: 20px; height: 20px; 
                border: 1px solid black; 
                font-weight: 600; 
                font-size: 18px; 
                border-radius: 4px; 
                padding: 10px; 
                text-align: center; 
                grid-row: 1/ 3; 
            }
            p{
                font-size: 14px; 
                margin: 0; 
                grid-column: 2; 
            }
        }
    }
}
