@use "../assets/base.scss";
@mixin bordertransition(){
    transition: 0.3s border-color ease-in-out !important; 
}
@mixin texttransiton(){
    transition: 0.3s color ease-in-out; 
}

.circlebtn-wrapper.light{
    @include bordertransition(); 
    border-color: black; 
    p{
        @include texttransiton(); 
        color: black 
    }
}
.circlebtn-wrapper.light:hover{
    @include bordertransition(); 
    border-color: base.$secondary-color; 
    p{
        @include texttransiton(); 
        color: base.$secondary-color; 
    }
}
.circlebtn-wrapper.dark{
    @include bordertransition(); 
    border-color: base.$background-color1; 
    p{
        @include texttransiton(); 
        color: base.$background-color1; 
    }
}

.circlebtn-wrapper.dark:hover{
    @include bordertransition(); 
    border-color: base.$secondary-color; 
    p{
        @include texttransiton(); 
        color: base.$secondary-color; 
    }
}

.circlebtn-wrapper{
    font-family: base.$font-primary; 
    border: 1px solid black; 
    border-radius: 50%;
    text-align: center;
    p{
        margin-top: 50%; 
        transform: translate(0, -50%); 
    }
}