.portal-adminfunctions-wrapper{
    .admin-function-wrapper{
        padding: 15px; 
        margin-bottom: 40px; 
        background-color: rgb(240,240,240); 
        border-radius: 10px; 
    }
    h1{
        font-size: 20px; 
    }
    code{
        font-size: 12px; 
    }
    button{
        margin: 0 10px 0 10px; 
        border: none; 
        border-radius: 7px; 
        padding: 7px; 
        transition: 0.2s ease-in-out background-color; 
        background-color: rgb(211,211,211); 
    }
    button:hover{
        cursor: pointer;
        transition: 0.2s ease-in-out background-color; 
        background-color: rgb(230,230,230);
    }
}