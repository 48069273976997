@use "../../../clearweb/assets/base.scss"; 

.portal-profile-question{
    margin-top: 40px; 
    margin-bottom: 20px; 
    input{
        margin-top: 5px; 
        margin-left: 5px; 
        width: 100%; 
        padding: 2px 0 2px 0; 
    }
    select{
        margin-top: 5px; 
        margin-left: 5px; 
        width: 100%;
        padding: 2px 0 2px 0; 

    }

    .multiselect-item{
        display: grid; 
        grid-template-columns: 40px 1fr; 

    }
}