@use '../assets/base.scss';

@mixin header-text() {
    font-family: base.$font-secondary;
    font-size: calc(0.35vw + 0.25vh + 40px);
    font-weight: 500;
}

@mixin paragraph-text() {
    font-size: calc(0.35vw + 0.25vh + 11px);
    letter-spacing: base.$paragraph1-letter-spacing;
    line-height: base.$paragraph1-line-height;
}

@mixin img-inactive() {
    height: 100%;
    transform: translate(-50px, 300px);
    opacity: 0;
    visibility: hidden;
    transition: cubic-bezier(.29, .86, .76, 1) 2s;
}

@mixin img-active() {
    transform: translate(-50px, 0);
    opacity: 1;
    visibility: visible;
    transition: cubic-bezier(.29, .86, .76, 1) 2s;
}

.home-wrapper.light {
    transition: 1s linear;
    background-color: base.$background-color1;

    a {
        transition: 1s linear;
        color: black;
    }
}

.home-wrapper.dark {
    transition: 1s linear;
    background-color: base.$background-color2;
    color: white;

    a {
        transition: 1s linear;
        color: white;
    }
}

hr {
    border: none;
    height: 2px;
    background: linear-gradient(90deg, #3498db, #e74c3c, #3498db);
    background-size: 300% 100%;
    animation: coolGlow 5s ease-in-out infinite;
}

.titleline {
    padding-top: 5px;
    margin-top: -5px;
}

@keyframes coolGlow {
    0% {
        background-position: 100% 0;
    }

    50% {
        background-position: 0 0;
    }

    100% {
        background-position: 100% 0;
    }
}




.home-wrapper {
    width: 100vw;
    background-color: base.$background-color1;
    font-family: base.$font-primary;


    .header-wrapper {
        position: relative;
        top: 0;
        padding-top: 50px;
        height: 80vh;
        width: 100vw;
        margin: auto;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        overflow: hidden;
        background: linear-gradient(315deg, rgba(115, 138, 180, 1) 3%, rgba(238, 239, 251, 1) 38%, rgb(240, 211, 229) 68%, rgb(244, 233, 240) 98%);
        animation: gradient 15s ease infinite;
        background-size: 300% 300%;
        background-attachment: fixed;

        .underlined {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: black;
            font-size: 5em;
            line-height: 1.2;
            text-decoration: none;
            background-image: linear-gradient(to right, yellow 0, yellow 100%);
            background-position: 0 1.2em;
            background-size: 0% 100%;
            background-repeat: no-repeat;
            transition: background-size 1s;
            animation: slide-in 5s;
            animation-timing-function: ease-in-out;
            text-shadow: 0 0 3px #b6d2db, 0 0 5px #dcecd4;



            &--gradient {
                background-position: 0 -0.1em;
                background-image: linear-gradient(to right, yellow 0, lightgreen 100%);
            }
        }


        @keyframes gradient {
            0% {
                background-position: 0% 0%;
            }

            50% {
                background-position: 100% 100%;
            }

            100% {
                background-position: 0% 0%;
            }
        }

        .wave {
            background: rgb(255 255 255 / 25%);
            border-radius: 1000% 1000% 0 0;
            position: absolute;
            width: 200%;
            height: 16em;
            animation: wave 30s -3s linear infinite;
            transform: translate3d(0, 0, 0);
            opacity: 0.8;
            bottom: -8em;
            left: 0;
        }

        .wave:nth-of-type(2) {
            bottom: -7.5em;
            animation: wave 45s linear reverse infinite;
            opacity: 0.8;
        }

        .wave:nth-of-type(3) {
            bottom: -7em;
            animation: wave 50s -1s reverse infinite;
            opacity: 0.9;
        }

        @keyframes wave {
            2% {
                transform: translateX(1);
            }

            25% {
                transform: translateX(-30%);
            }

            50% {
                transform: translateX(-60%);
            }

            75% {
                transform: translateX(-30%);
            }

            100% {
                transform: translateX(1);
            }
        }

        @mixin sp-layout {
            @media screen and (max-width: 750px) {
                @content;
            }
        }

        @function random_range($min, $max) {
            $rand: random();
            $random_range: $min + floor($rand * (($max - $min) + 1));
            @return $random_range;
        }

    }


    @media screen and (max-width: base.$mobile-breakpoint1) {
        .header-wrapper {
            .underlined {
                font-size: 3rem;
            }
        }
    }

    .mission-story-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }

    .front .mission-wrapper {
        transform: rotateY(180deg);
    }

    .back .story-wrapper {
        transform: rotateY(180deg);
    }

    .mission-wrapper,
    .story-wrapper {
        position: absolute;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: transform 0.8s;
    }

    .mission-wrapper {
        background-color: #eeeffb;
        color: black;
        border: 5px solid #738ab4;

    }

    .story-wrapper {
        background-color: #f4e9f0;
        color: #656565;
        border: 5px solid #f0d3e5;

    }

    .mission-story-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 50px;
        margin-bottom: 100px;
        padding-bottom: 25px;

        .flip-button {
            z-index: 2;
            position: relative;
            bottom: -50px;
            left: 50px;
            appearance: none;
            background-color: transparent;
            border: 2px solid #1A1A1A;
            border-radius: 15px;
            box-sizing: border-box;
            color: #3B3B3B;
            cursor: pointer;
            display: inline-block;
            font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 16px;
            font-weight: 600;
            line-height: normal;
            margin: 0;
            min-height: 60px;
            min-width: 0;
            outline: none;
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 2%;
            padding-right: 2%;
            text-align: center;
            text-decoration: none;
            transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            width: 10%;
            will-change: transform;
        }

        @media screen and (max-width: base.$mobile-breakpoint1) {
            .flip-button {
                left: 25px;
            }
        }

        .flip-button:disabled {
            pointer-events: none;
        }

        .flip-button:hover {
            color: #fff;
            background-color: #1A1A1A;
            box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
            transform: translateY(-2px);
        }

        .flip-button:active {
            box-shadow: none;
            transform: translateY(100);
        }



        .mission-wrapper {
            margin-top: 10px;
            margin-left: 10px;
            display: grid;
            grid-template-columns: 1fr;
            height: 57vh;
            width: 50vw;

            .mission-header-text {
                padding-top: 50px;
                text-align: center;
                @include header-text();
            }

            .mission-text {
                text-align: center;
                padding-left: 10px;
                padding-right: 10px;
                @include paragraph-text();
            }
        }

        .story-wrapper {

            margin-top: 10px;
            margin-left: 10px;
            display: grid;
            grid-template-columns: 1fr;
            height: 57vh;
            width: 50vw;


            .story-header-text {
                text-align: center;
                padding-top: 50px;
                @include header-text();
            }

            .story-text {
                text-align: center;
                padding-left: 20px;
                padding-right: 20px;
                @include paragraph-text();
            }
        }

        @media screen and (max-width: 1080px) {
            .story-wrapper {
                height: 50vh;
                width: 94vw;
            }

            .mission-wrapper {
                height: 50vh;
                width: 94vw;
            }
        }


        .video-wrapper {
            margin-left: 50px;
            margin-right: 50px;
            padding-top: 56.25%;
            width: auto;
            height: auto;
            position: relative;
            border: 5px solid rgb(240, 211, 229);
        }

        .story-video {
            position: absolute;
            top: 0;
            left: 0;
        }

        .story-video>.video-wrapper {
            position: absolute; // Scaling will occur since parent is relative now
        }

    }

    @media screen and (max-width: 1080px) {
        .mission-story-wrapper {
            display: grid;
            grid-template-columns: 1fr;
            margin-top: 50px;
            margin-bottom: 30px;
        }

        .video-wrapper {
            margin-top: 350px;
        }
    }


    .people-wrapper {
        display: grid;
        grid-template-columns: 18% 1fr;
        grid-template-rows: min-content 360px;
        margin-bottom: 50px;
        padding-top: 0px;
        padding-bottom: 50px;

        ::-webkit-scrollbar {
            background-color: base.$primary-color;
            height: 4px;
        }

        ::-webkit-scrollbar-thumb {
            background: black;
        }

        .people-header {
            @include header-text();
            grid-column: 1 / 3;
            margin: 75px 0;
            margin-left: 50px;
        }

        .people-menu-wrapper {
            margin-left: 65px;

            .menu-item {
                width: fit-content;
                font-size: 20px;
                border-bottom: 2px solid transparent;
                transition: linear 0.3s;
            }

            .menu-item:hover {
                cursor: pointer;
                border-bottom: 2px solid black;
                transition: linear 0.3s;
            }

            .menu-item.active {
                border-bottom: 2px solid black;
            }
        }

        .button {
            position: relative;
            z-index: 0;
            width: 240px;
            height: 56px;
            text-decoration: none;
            font-size: 14px;
            font-weight: bold;
            color: var(--line_color);
            letter-spacing: 2px;
            transition: all .3s ease;
        }

        .button:hover {
            letter-spacing: 6px;
        }


        .people-card-wrapper {
            grid-column: 2;
            margin-right: 20px;
            overflow: hidden;
            overflow-x: auto;

            .card-wrapper {
                position: relative;

                animation-duration: 1s;
                animation-name: slideup;

                .mentors-card-wrapper {
                    display: grid;
                    grid-template-columns: min-content min-content min-content min-content min-content;
                    grid-column-gap: 50px;
                }

                .company-logos {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, 40px);
                }

                .team-card-wrapper {
                    display: grid;
                    grid-template-columns: repeat(6, min-content);
                    grid-column-gap: 50px;
                }

            }

            .card-wrapper.inactive {
                display: none;
            }


            .text-wrapper {
                animation-duration: 1s;
                animation-name: slideup;
                width: 100%;

                .mentors-link {
                    position: absolute;
                }
            }

            .text-wrapper.inactive {
                display: none;
            }

            @keyframes slideup {
                from {
                    opacity: 0;
                    transform: translate(0, 50px);
                }

                to {
                    opacity: 1;
                    transform: translate(0, 0);
                }
            }

        }
    }

    @media screen and (max-width: base.$mobile-breakpoint1) {
        .people-wrapper {
            grid-template-columns: 1fr;
            width: 100vw;
            grid-template-rows: min-content min-content 400px;
            overflow: auto;
            margin-bottom: 100px;

            .people-header {
                margin-bottom: 20px;
            }

            .people-menu-wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-row: 2;
                margin: 0 20px 0 20px;

                .menu-item {
                    font-size: 15px;
                    text-align: center;
                    width: fit-content;
                    margin: 0 auto 0 auto;

                }
            }

            .people-card-wrapper {
                grid-row: 3;
                grid-column: 1;
                margin: 40px 20px 0 20px;

                .cards {
                    width: 130px;
                    height: 200px;

                    p {
                        @include paragraph-text();
                        font-size: 13px;
                        height: 150px;
                        overflow: auto;
                    }

                    img {
                        position: aboslute;
                        width: 120px;
                        height: 120px;
                        margin: 0 auto 0 auto;
                    }
                }
            }
        }

    }

    .news-wrapper {
        padding-top: 100px;

        ::-webkit-scrollbar {
            background-color: base.$primary-color;
            height: 4px;
        }

        ::-webkit-scrollbar-thumb {
            background: black;
        }

        .news-header {
            @include header-text();
            margin-left: 50px;
        }

        .news-cards-wrapper {
            margin-top: 50px;
            margin-left: 70px;
            margin-right: 70px;
            padding-bottom: 40px;
            margin-bottom: 50px;

            height: 60vh;
            display: grid;
            grid-template-columns: repeat(7, 400px);
            grid-gap: 50px;

            overflow-x: scroll;

            .news-card {
                height: 100%;
                width: 100%;
                position: relative;
                background-size: cover;

                .news-card-header {
                    overflow: hidden;
                    color: base.$background-color1;
                    font-size: 25px;
                    text-transform: capitalize;
                    position: absolute;
                    bottom: 0;
                    margin: 0 20px 30px 30px;
                    transition: 0.5s ease-in-out;
                }
            }

            .news-card:hover {
                cursor: pointer;

                .news-card-header {
                    color: base.$primary-color;
                    transition: 0.2s ease-in-out;
                }
            }
        }
    }

    @media screen and (max-width: base.$mobile-breakpoint1) {
        .news-wrapper {
            .news-cards-wrapper {
                margin-left: 20px;
                margin-right: 20px;
                grid-template-columns: repeat(7, 200px);
                height: 50vh;

                .news-card {
                    .news-card-header {
                        font-size: 22px;
                    }
                }

            }
        }
    }

    .contact-wrapper {
        position: relative;

        z-index: 3;
    }
}

.button-glow {
    background-color: white;
    color: black;
    border: none;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin: 4px 2px;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 0 0 10px white;
    border-radius: 10px; /* Rounded corners */
    font-weight: bold; /* Bold text */
    margin-top: 25px;
}

.button-glow:hover {
    box-shadow: 0 0 20px white, 0 0 30px white;
}