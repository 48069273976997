@use "../../clearweb/assets/base.scss"; 
.portal-profile-wrapper{
    margin-bottom: 50px; 
    h1{
        grid-column-start: 1; grid-column-end: 4; 
        font-size: 20px;
        border-bottom: 1px solid black; 
        padding-bottom: 5px; 
        margin: 0 0 5px 0; 
    }
    input{
        font: base.$font-primary; 
        font-size: 15px; 
        border: none; 
        border-bottom: 1px dashed black; 
        background-color: transparent;
    }
    input:focus{
        transition: border-bottom 0.1s linear; 
        outline: none; 
    }
    .portal-your-info-wrapper{
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-row-gap: 10px; 
        grid-column-gap: 15px; 
        
        b{
            font-size: 16px; 
        }
        p{
            margin: 0; 
        }
        
    }
    .portal-profile-questions{
        margin-top: 40px; 
        margin-bottom: 20px; 
        input{
            margin-left: 5px; 
            width: minmax(100px, 100%); 
            padding: 2px 0 2px 0; 
        }
    }

    .profile-save-button{
        position:fixed;
        bottom: 0;  
        right: 0; 
        margin: 0 20px 20px 0; 
        background: rgb(0,200,0,0.9);
        padding: 10px; 
        border-radius: 7px;
    }
    .profile-save-button:hover{
        cursor: pointer; 
    }

    

}

@media only screen and (max-width: 800px){
    .portal-profile-wrapper{
        .portal-your-info-wrapper{
            display: grid; 
            grid-template-columns: 1fr;
            h1{
                grid-column: 1;
            }
        }
        h1{
            grid-column: 1; 
        }
    }
}