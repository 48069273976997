@use "../assets/base.scss"; 

.fancy-link-wrapper{
    height: min-content;
    margin: 0;  
    overflow: hidden; 
    .fancy-link-bar{
        border: 1px solid base.$secondary-color; 
    }
}
.fancy-link-wrapper:hover{
    .fancy-link-bar{
        transition: 0.2s ease-in-out; 
        transform: translate(0,0) !important; 
    }
}